import './SignUp.css';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import subscribeUser from '../Mailchimp/MailchimpConnection';
import logo from "./images/welleo_logo_transp.png";

const OpenPDF = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const pdfUrl = '/Privacy Policy.pdf';

    if (isMobile) {
        window.location.href = pdfUrl;
    } else {
        window.open(pdfUrl, '_blank');
    }
};

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Invalid email';
        }

        const domainRegex = /\.(com|se|ru|net|org|info|biz|gov|edu|mil|int|name|pro|aero|coop|museum|xyz|top|site|online|tech|store|art|blog|club|design|space|wiki|shop|us|uk|ca|de|fr|es|it|nl|au|ch|jp|cn|in)$/i;
        if (!domainRegex.test(email)) {
            return 'Invalid email';
        }

        return '';
    };

    const handleChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);

        const validationError = validateEmail(newEmail);
        setMessage(validationError);
        setMessageType(validationError ? 'error' : '');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const validationError = validateEmail(email);
        if (validationError) {
            setMessage(validationError);
            setMessageType('error');
            setIsLoading(false);
            return;
        }

        try {
            await subscribeUser(email, isChecked2, isChecked);
            setMessage('Email submitted successfully!');
            setMessageType('success');
        } catch (error) {
            setMessage(`Failed to submit email: ${error.message}`);
            setMessageType('error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleCheckboxChange2 = (event) => {
        setIsChecked2(event.target.checked);
    };

    return (
        <div className="HomePage3">
            <Helmet>
                <link rel="canonical" href="https://welleoapp.com/SignUp" />
                <meta property="og:image" content={logo} />
                <meta property="og:url" content="https://welleoapp.com/SignUp" />
                <meta name="twitter:image" content={logo} />
            </Helmet>
            <div className="overlay"></div>
            <div className="SignUpBody">
                <div className="Content1">
                    <h1 className="H12">CONTROL YOUR HEALTH AND PERFORMANCE</h1>
                    <p className="SignUpP">We will notify you when Welleo is available.</p>
                    <form onSubmit={handleSubmit} className="InputButton">
                        <input
                            type="email"
                            placeholder="Enter Email"
                            className="Input-Body2"
                            value={email}
                            onChange={handleChange}
                            required
                        />
                        <div className="CheckBoxDiv">
                            <input
                                type="checkbox"
                                id="checkbox"
                                name="newsletter"
                                value="subscribe"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label className="checkboxText" htmlFor="checkbox">
                                Send me regular health and fitness updates
                            </label>
                        </div>
                        <div className="CheckBoxDiv">
                            <input
                                type="checkbox"
                                id="checkbox2"
                                name="app"
                                value="subscribe"
                                checked={isChecked2}
                                onChange={handleCheckboxChange2}
                            />
                            <label className="checkboxText" htmlFor="checkbox2">
                                By signing up, you confirm that you are 13 years old or older, have read and agree with the
                                <a href="#" className="colored-text" onClick={(event) => OpenPDF(event)}>Privacy Policy</a>
                            </label>
                        </div>
                        <button type="submit" className="SignUpButton buttonSign" disabled={!isChecked2 || isLoading}>
                            {isLoading ? 'Processing...' : 'SIGN UP'}
                        </button>
                        <div className="ResponseMessage">
                            {message && <p className={`message ${messageType}`}>{message}</p>}
                        </div>
                    </form>
                </div>
                <div className="Content2">
                    <img src={require('../Web_Shuffle2/0080.png')} alt="Welleo120" className="Image" />
                </div>

            </div>
        </div>
    );
};

export default SignUp;
