import React from 'react';
import './Footer3.css'; 
import { FaLinkedin } from 'react-icons/fa';
import linkedin from './images/linkedin 1.png';

function Footer3() {
    return (
        <footer className="footer3">
            <div className="footer-content3">
                <span className="footer-left2">
                    FIND US ON:
                    <div className="Icons2">
                        <a href="https://www.linkedin.com/company/welleo/" target="_blank" rel="noopener noreferrer" className="FooterLinks2">
                            <img src={linkedin} alt="LinkedIn" className="logos2" />
                        </a>
                    </div>
                </span>
                <span className="footer-right2">
                    &copy; 2024 Welleo AB<br></br>
                    All rights reserved<br></br>
                    Org.nr: 559474-8500<br></br>
                    info@welleoapp.com<br></br>
                </span>
            </div>
        </footer >
    );
}

export default Footer3;