import axios from 'axios';

const subscribeUser = async (email, release, newsletterRelease) => {
    try {
        console.log(`Email: ${email}`);
        console.log(`Release: ${release}`);
        console.log(`Newsletter Release: ${newsletterRelease}`);

        const requestBody = {
            email,
            release,
            newsletterRelease,
        };

        const response = await axios.post('https://us-central1-strengthhub-app.cloudfunctions.net/addUserToMailchimp', requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        console.log('Email submitted successfully!');
        console.log('Success:', response.data);
        return response.data;

    } catch (error) {
        if (error.response) {
            console.error('Failed to submit email:', error.response.data);
            throw new Error(error.response.data.error || 'Failed to submit email');
        } else if (error.request) {
            console.error('No response received:', error.request);
            throw new Error('No response received from the server');
        } else {
            console.error('Error subscribing user to Mailchimp:', error.message);
            throw new Error(error.message);
        }
    }
};

export default subscribeUser;
