import React from 'react';
import './Footer.css';
import scrollGif from './images/scrollformore.gif';

function Footer() {
    return (
        <footer className="footer">
            <img src={scrollGif} alt="Welleo120" className="ScrollToFind" />
        </footer>
    );
}

export default Footer;