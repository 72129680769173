import React from 'react';
import './Footer2.css'; // You can define your footer styles in a separate CSS file if needed
import instagram from './images/instagram 1.png';
import youtube from './images/youtube 1.png';
import linkedin from './images/linkedin 1.png';

function Footer2() {
    return (
        <footer className="footer2">
            <div className="footer-content2">
                <span className="footer-left">
                    FIND US ON:
                    <div className="Icons">
                        <a href="https://www.linkedin.com/company/welleo/" target="_blank" rel="noopener noreferrer" className="FooterLinks">
                            <img src={linkedin} alt="LinkedIn" className="logos" />
                        </a>
                    </div>

                </span>
                <span className="footer-right">
                    &copy; 2024 Welleo AB<br />
                    All rights reserved<br />
                    Org.nr: 559474-8500<br />
                    info@welleoapp.com<br />
                </span>
            </div>
        </footer>
    );
}

export default Footer2;
