import React, { useState, useEffect } from 'react';
import './App.css';

import image0000 from './Web_Shuffle2/0000.png';
import image0001 from './Web_Shuffle2/0001.png';
import image0002 from './Web_Shuffle2/0002.png';
import image0003 from './Web_Shuffle2/0003.png';
import image0004 from './Web_Shuffle2/0004.png';
import image0005 from './Web_Shuffle2/0005.png';
import image0006 from './Web_Shuffle2/0006.png';
import image0007 from './Web_Shuffle2/0007.png';
import image0008 from './Web_Shuffle2/0008.png';
import image0009 from './Web_Shuffle2/0009.png';
import image0010 from './Web_Shuffle2/0010.png';
import image0011 from './Web_Shuffle2/0011.png';
import image0012 from './Web_Shuffle2/0012.png';
import image0013 from './Web_Shuffle2/0013.png';
import image0014 from './Web_Shuffle2/0014.png';
import image0015 from './Web_Shuffle2/0015.png';
import image0016 from './Web_Shuffle2/0016.png';
import image0017 from './Web_Shuffle2/0017.png';
import image0018 from './Web_Shuffle2/0018.png';
import image0019 from './Web_Shuffle2/0019.png';
import image0020 from './Web_Shuffle2/0020.png';
import image0021 from './Web_Shuffle2/0021.png';
import image0022 from './Web_Shuffle2/0022.png';
import image0023 from './Web_Shuffle2/0023.png';
import image0024 from './Web_Shuffle2/0024.png';
import image0025 from './Web_Shuffle2/0025.png';
import image0026 from './Web_Shuffle2/0026.png';
import image0027 from './Web_Shuffle2/0027.png';
import image0028 from './Web_Shuffle2/0028.png';
import image0029 from './Web_Shuffle2/0029.png';
import image0030 from './Web_Shuffle2/0030.png';
import image0031 from './Web_Shuffle2/0031.png';
import image0032 from './Web_Shuffle2/0032.png';
import image0033 from './Web_Shuffle2/0033.png';
import image0034 from './Web_Shuffle2/0034.png';
import image0035 from './Web_Shuffle2/0035.png';
import image0036 from './Web_Shuffle2/0036.png';
import image0037 from './Web_Shuffle2/0037.png';
import image0038 from './Web_Shuffle2/0038.png';
import image0039 from './Web_Shuffle2/0039.png';
import image0040 from './Web_Shuffle2/0040.png';
import image0041 from './Web_Shuffle2/0041.png';
import image0042 from './Web_Shuffle2/0042.png';
import image0043 from './Web_Shuffle2/0043.png';
import image0044 from './Web_Shuffle2/0044.png';
import image0045 from './Web_Shuffle2/0045.png';
import image0046 from './Web_Shuffle2/0046.png';
import image0047 from './Web_Shuffle2/0047.png';
import image0048 from './Web_Shuffle2/0048.png';
import image0049 from './Web_Shuffle2/0049.png';
import image0050 from './Web_Shuffle2/0050.png';
import image0051 from './Web_Shuffle2/0051.png';
import image0052 from './Web_Shuffle2/0052.png';
import image0053 from './Web_Shuffle2/0053.png';
import image0054 from './Web_Shuffle2/0054.png';
import image0055 from './Web_Shuffle2/0055.png';
import image0056 from './Web_Shuffle2/0056.png';
import image0057 from './Web_Shuffle2/0057.png';
import image0058 from './Web_Shuffle2/0058.png';
import image0059 from './Web_Shuffle2/0059.png';
import image0060 from './Web_Shuffle2/0060.png';
import image0061 from './Web_Shuffle2/0061.png';
import image0062 from './Web_Shuffle2/0062.png';
import image0063 from './Web_Shuffle2/0063.png';
import image0064 from './Web_Shuffle2/0064.png';
import image0065 from './Web_Shuffle2/0065.png';
import image0066 from './Web_Shuffle2/0066.png';
import image0067 from './Web_Shuffle2/0067.png';
import image0068 from './Web_Shuffle2/0068.png';
import image0069 from './Web_Shuffle2/0069.png';
import image0070 from './Web_Shuffle2/0070.png';
import image0071 from './Web_Shuffle2/0071.png';
import image0072 from './Web_Shuffle2/0072.png';
import image0073 from './Web_Shuffle2/0073.png';
import image0074 from './Web_Shuffle2/0074.png';
import image0075 from './Web_Shuffle2/0075.png';
import image0076 from './Web_Shuffle2/0076.png';
import image0077 from './Web_Shuffle2/0077.png';
import image0078 from './Web_Shuffle2/0078.png';
import image0079 from './Web_Shuffle2/0079.png';
import image0080 from './Web_Shuffle2/0080.png';

const imagePaths = [
    image0000, image0001, image0002, image0003, image0004, image0005, image0006,
    image0007, image0008, image0009, image0010, image0011, image0012, image0013,
    image0014, image0015, image0016, image0017, image0018, image0019, image0020,
    image0021, image0022, image0023, image0024, image0025, image0026, image0027,
    image0028, image0029, image0030, image0031, image0032, image0033, image0034,
    image0035, image0036, image0037, image0038, image0039, image0040, image0041,
    image0042, image0043, image0044, image0045, image0046, image0047, image0048,
    image0049, image0050, image0051, image0052, image0053, image0054, image0055,
    image0056, image0057, image0058, image0059, image0060, image0061, image0062,
    image0063, image0064, image0065, image0066, image0067, image0068, image0069,
    image0070, image0071, image0072, image0073, image0074, image0075, image0076,
    image0077, image0078, image0079, image0080
];

const alttext = "ImageSlider";

const ImageSlider2 = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [scrollCount, setScrollCount] = useState(0);
    const [isTouchpad, setIsTouchpad] = useState(false);

    useEffect(() => {
        const detectTouchpad = (e) => {
            if (e.wheelDeltaY && (e.wheelDeltaY === e.deltaY * -3)) {
                setIsTouchpad(true);
            } else {
                setIsTouchpad(false);
            }
        };

        const handleScroll = (event) => {
            const { deltaY } = event;
            const scrollIncrement = isTouchpad ? 5 : 1; // Adjusted increments

            setScrollCount(prev => {
                const newCount = prev + 1;
                if (newCount % scrollIncrement === 0) {
                    setCurrentIndex(prevIndex => {
                        if (deltaY > 0) {
                            return Math.min(prevIndex + 1, imagePaths.length - 1);
                        } else {
                            return Math.max(prevIndex - 1, 0);
                        }
                    });
                }
                return newCount;
            });
        };

        window.addEventListener('wheel', detectTouchpad, { passive: true });
        window.addEventListener('wheel', handleScroll, { passive: false });

        return () => {
            window.removeEventListener('wheel', detectTouchpad);
            window.removeEventListener('wheel', handleScroll);
        };
    }, [isTouchpad]);

    return (
        <div>
            <img className="Image0009" src={imagePaths[currentIndex]} alt={alttext} />
        </div>
    );
};

export default ImageSlider2;
