import "./WeAreWelleo.css";
import '../App.css';
import Footer2 from './Footer2';
import { FaLinkedin } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import React, { useState } from 'react';
import Footer from "./Footer";
import { isMobile } from 'react-device-detect';
import logo from "./images/welleo_logo_transp.png";
function WeAreWelleo({ navigateTo }) {
    return (
        <div className="WeAreWelleo">
            {/*<div className="overlay"></div>*/}

            <Helmet>
                <link rel="canonical" href="https://welleoapp.com/WeAreWelleo" />
                <meta property="og:image" content={logo} />
                <meta property="og:url" content="https://welleoapp.com/WeAreWelleo" />
                <meta name="twitter:image" content={logo} />
            </Helmet>
            <Body1 />
            <Footer2></Footer2>
        </div>
    );
}

const OpenPDF = (event) => {

    const pdfUrl = '/CodeofConduct.pdf';

    if (isMobile) {
        window.location.href = pdfUrl;
    } else {
        window.open(pdfUrl, '_blank');
    }
};

function Body1({ navigateTo }) {
    return (
        <div className="WRW-Container">
            <div className="WRW-Item">
                <div className="WRW-Item-Item1">
                    <h3 className="BolderH3"><span className="colored-text3">WE ARE WELLEO</span></h3>
                    <p className="BolderP">At Welleo, we are guided by a set of values that form the cornerstone of our commitment to fostering a healthier world. These values permeate every aspect of our operations, influencing how we conduct ourselves as individuals and as a collective community.</p>
                </div>
                <div className="WRW-Item-Item2">
                    <img className="WRW-Img1" src={require('./images/girl_jumping_image.png')} alt="Girl jumping" />
                </div>
            </div>
            <div className="WRW-Title1">
                <h2 className="OurValues">OUR VALUES</h2>
            </div>
            <div className="WRW-Containers2">
            </div>
            <div className="WRW-Item WRW-Item1">
                <img className="WRW-Img" src={require('./images/wellness_image.png')} alt="Wellness" />
                <h4 className="Values">WELLNESS</h4>
                <p>We are committed to promoting holistic well-being, encompassing physical, mental, and emotional health. Our products and services are designed to support individuals on their journey to optimal wellness,
                    fostering habits that promote longevity and vitality.</p>
            </div>
            <div className="WRW-Item WRW-Item2">
                <img className="WRW-Img" src={require('./images/innovation__image.png')} alt="Innovation" />
                <h4 className="Values">INNOVATION</h4>
                <p>We embrace innovation as a driving force behind our quest for excellence. We continuously seek new and creative ways to empower individuals to achieve their fitness and wellness goals, pushing the boundaries
                    of what is possible in the health and fitness landscape.</p>
            </div>
            <div className="WRW-Item WRW-Item3">
                <img className="WRW-Img" src={require('./images/integrity_image.png')} alt="Integrity" />
                <h4 className="Values">INTEGRITY</h4>
                <p>We hold ourselves to the highest standards of honesty, transparency, and ethical behavior. Integrity is the bedrock upon which we build trust with our stakeholders, and we recognize that every
                    decision and action we take must be grounded in integrity.</p>
            </div>
            <div className="WRW-Item WRW-Item4">
                <img className="WRW-Img" src={require('./images/collaboration_image.png')} alt="Collaboration" />
                <h4 className="Values">COLLABORATION</h4>
                <p>By fostering an environment of collaboration, we bring together diverse perspectives and expertise to achieve our shared goals. We actively seek out partnerships and collaborations with individuals,
                    and organizations that share our passion for wellness.</p>
            </div>
            <div className="WRW-Item WRW-Item5">
                <img className="WRW-Img" src={require('./images/community_image.png')} alt="Community"/>
                <h4 className="Values">COMMUNITY</h4>
                <p>We actively engage with our community, listening to their needs, feedback, and concerns. We strive to be responsive and accountable, building meaningful relationships
                    based on mutual respect, trust, and collaboration.</p>
            </div>
            <div className="WRW-Item WRW-Item6">
                <img className="WRW-Img" src={require('./images/CoC_image.png')} alt="Code of Conduct"/>
                <h4 className="Values">CODE OF CONDUCT</h4>
                <p>Read our full Code of Conduct to familiarize
                    yourself with what we believe and stand for.</p>
                <button className="SignUpButton" onClick={() => OpenPDF()}>READ MORE</button>
            </div>

            <div className="WRW-Title2">
                <h2 className="OurTeam">OUR TEAM</h2>
            </div>
            <div className="WRW-Team WRW-Item7">
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/emil-bergqvist-699a04213/" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_emil.png')} alt="Team Member 1" className="team-image" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/hannes-jerdborg-47302421a/" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_hannes.png')} alt="Team Member 2" className="team-image" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/linus-bergqvist-63600526a/" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_linus.png')} alt="Team Member 3" className="team-image" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/marcus-videg%C3%A5rd-17586a23a" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_marcus.png')} alt="Team Member 4" className="team-image" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/oliver-m%C3%A1rkus-johansson-b29659235/" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_oliver.png')} alt="Team Member 5" className="team-image" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/pontus-engstr%C3%B6m-954676317" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_pontus.png')} alt="Team Member 6" className="team-image" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/gustav-skogstr%C3%B6m-65655926a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_gustav.png')} alt="Team Member 7" className="team-image" />
                    </a>
                </div>
            </div>


            {/*<div className="WRW-Item WRW-Item1">*/}
            {/*    <img className="WRW-Img" src={require('./images/wellness_image.png')} />*/}
            {/*    <h4 className="Values">WELLNESS</h4>*/}
            {/*    <p>We are committed to promoting holistic well-being, encompassing physical, mental, and emotional health. Our products and services are designed to support individuals on their journey to optimal wellness,*/}
            {/*        fostering habits that promote longevity and vitality.</p>*/}
            {/*</div>*/}
        </div>
    );
}

export default WeAreWelleo;